import React, { useContext, useEffect, useMemo, useState } from 'react';

import { View } from 'react-native';
import { Alert } from 'components/Alert';
import { AvoidingView } from 'components/AvoidingView';
import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { ButtonBlue } from 'components/ButtonBlue';
import { Centered } from 'components/Centered';
import { Footer } from 'components/Footer';
import { FormFieldText } from 'components/FormFieldText';
import { FormFieldTextDate } from 'components/FormFieldTextDate';
import { FormTableSelect } from 'components/FormTableSelect';
import { SizeClassView, sizeClasses } from 'components/SizeClassView';
import { Spacer } from 'components/Spacer';
import { Text } from 'components/Text';
import { VerticalScroll } from 'components/VerticalScroll';

import { ProfileContext } from 'contexts/ProfileContext';
import { useQueryClient } from '@tanstack/react-query';
import { refreshMe } from 'hooks/useMe';
import { useGetTeams } from 'hooks/useTeam';
import { useCreateTrainer } from 'hooks/useTrainer';

export const TrainerAddScreen = ({ navigation, route }) => {
  const queryClient = useQueryClient()
  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => { return currentClub?.club?.id }, [currentClub]);
  const leagueId = useMemo(() => {
    if (currentClub) {
      return currentClub?.club.affiliations[0]?.leagueId;
    }
  }, [currentClub]);
  const { data: teams } = useGetTeams({ clubId });
  const { mutate: createTrainer, isPending, isSuccess, isError, error } = useCreateTrainer({ clubId });

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [certificateFirstAidExpiresOn, setCertificateFirstAidExpiresOn] = useState('');
  const [certificateCprExpiresOn, setCertificateCprExpiresOn] = useState('');
  const [certificateErcExpiresOn, setCertificateErcExpiresOn] = useState('');
  const [selectedTeams, setSelectedTeams] = useState([]);

  const [activeDropDown, setActiveDropDown] = useState('');

  const teamItems = useMemo(() => {
    if (!teams) return [];

    var newTeams = teams
    return newTeams.map((team) => ({
      key: team.id,
      value: team.name,
    }))

  }, [teams])

  const canSave = useMemo(() => {
    if (email !== '') {
      // let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      let reg = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      return (reg.test(email) === true)
    }
    return false;
  }, [email])

  const save = () => {
    const club_trainer = {
      invitation_email: email,
      invitation_first_name: firstName,
      invitation_last_name: lastName,
      certificate_first_aid_expires_on: certificateFirstAidExpiresOn,
      certificate_cpr_expires_on: certificateCprExpiresOn,
      certificate_erc_expires_on: certificateErcExpiresOn,
      teams: selectedTeams
    }
    createTrainer({ clubId, club_trainer });
  };

  useEffect(() => {
    if (isError) Alert('Something went wrong', error?.toString());
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      refreshMe({ queryClient });
      navigation.navigate('TrainersScreen');
    }
  }, [isSuccess]);

  return (
    <>
      <BarHeaderSmall
        title={'Add a trainer'}
        leftButtonTitle="Cancel"
        onLeftPress={() => {
          navigation.navigate('TrainersScreen');
        }}
      />
      <AvoidingView>
        <SizeClassView size={sizeClasses.regular}>
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View style={{ flex: 1 }}>
              <VerticalScroll>
                <Centered>
                  <FormFieldText
                    label="Email"
                    value={email}
                    onChangeText={setEmail}
                    keyboardType="email-address"
                    autoCapitalize={false}
                    autoFocus={true}
                  />
                  <FormFieldText
                    label="First Name"
                    value={firstName}
                    onChangeText={setFirstName}
                  />
                  <FormFieldText
                    label="Last Name"
                    value={lastName}
                    onChangeText={setLastName}
                  />
                  <FormFieldTextDate
                    label="First Aid certificate expiry (3 year coverage)"
                    required={false}
                    value={certificateFirstAidExpiresOn}
                    onBlur={(value) => {
                      setCertificateFirstAidExpiresOn(value);
                    }}
                  />
                  <FormFieldTextDate
                    label="CPR certificate expiry (1 year coverage)"
                    required={false}
                    value={certificateCprExpiresOn}
                    onBlur={(value) => {
                      setCertificateCprExpiresOn(value);
                    }}
                  />
                  <FormFieldTextDate
                    label="ERC certificate expiry"
                    required={false}
                    value={certificateErcExpiresOn}
                    onBlur={(value) => {
                      setCertificateErcExpiresOn(value);
                    }}
                  />
                  <Spacer type="bottom" size="large" />
                </Centered>
              </VerticalScroll>
            </View>
            <View style={{ flex: 1 }}>
              <VerticalScroll>
                <Centered>
                  {teamItems.length == 0 ? (
                    <>
                      <Spacer type="bottom" size="large" />
                      <Text style={{ textAlign: 'center' }}>No teams yet</Text>
                      <Spacer type="bottom" size="large" />
                    </>
                  ) : (
                    <FormTableSelect
                      label={'Assign teams'}
                      values={teamItems}
                      initialKeys={selectedTeams}
                      multiselect={true}
                      onSelected={setSelectedTeams}
                    />
                  )}
                  <Spacer type="bottom" size="large" />
                </Centered>
              </VerticalScroll>
            </View>
          </View>
        </SizeClassView>
        <SizeClassView size={sizeClasses.compact}>
          <VerticalScroll>
            <Centered>
              <FormFieldText
                label="Email"
                value={email}
                onChangeText={setEmail}
                keyboardType="email-address"
                autoCapitalize={false}
                autoFocus={true}
              />
              <FormFieldText
                label="First Name"
                value={firstName}
                onChangeText={setFirstName}
              />
              <FormFieldText
                label="Last Name"
                value={lastName}
                onChangeText={setLastName}
              />
              <FormFieldTextDate
                label="First Aid certificate expiry (3 year coverage)"
                required={false}
                value={certificateFirstAidExpiresOn}
                onBlur={(value) => {
                  setCertificateFirstAidExpiresOn(value);
                }}
              />
              <FormFieldTextDate
                label="CPR certificate expiry (1 year coverage)"
                required={false}
                value={certificateCprExpiresOn}
                onBlur={(value) => {
                  setCertificateCprExpiresOn(value);
                }}
              />
              <FormFieldTextDate
                label="ERC certificate expiry"
                required={false}
                value={certificateErcExpiresOn}
                onBlur={(value) => {
                  setcertificateErcExpiresOn(value);
                }}
              />
              <Spacer type="bottom" size="large" />

              {teamItems.length == 0 ? (
                <>
                  <Spacer type="bottom" size="large" />
                  <Text style={{ textAlign: 'center' }}>No teams yet</Text>
                  <Spacer type="bottom" size="large" />
                </>
              ) : (
                <FormTableSelect
                  label={'Assign teams'}
                  values={teamItems}
                  initialKeys={selectedTeams}
                  multiselect={true}
                  onSelected={setSelectedTeams}
                />
              )}
              <Spacer type="bottom" size="large" />
            </Centered>
          </VerticalScroll>
        </SizeClassView>
        <Footer>
          <Centered>
            <ButtonBlue
              label="Add to whitelist"
              isDisabled={!canSave}
              onPress={() => {
                save();
              }}
            />
          </Centered>
          <SizeClassView size={sizeClasses.compact}>
            <Spacer type="bottom" size="large" />
          </SizeClassView>
        </Footer>
      </AvoidingView>
    </>
  );
};
